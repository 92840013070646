.form-home-client .form-home-client__col {
	display: flex;

}

.form-home-client .form-home-client__col:first-child {
	position: sticky;
    top: 170px;
    max-height: calc(100vh - 170px);
    overflow-y: auto;
}

.form-home-client .form-home-client__tabs {
	flex-direction: column;
	text-align    : left;
}

.form-home-client .form-home-client__content {
	width: 100%;
}

/* .form-home {
	padding: 20px;
	border : 1px solid black;
	border-radius: 3px;
}

.form-home .form-home__name-form {
	text-align : left;
	font-size  : 30px;
	font-weight: 400;
	line-height: 21px;
} */

.form-home-client__content .form-home-client__content-child h4 {
	text-align    : left;
	font-family   : "Lato", Sans-serif;
	font-size     : 20px;
	font-weight   : 400;
	line-height   : 21px;
	color         : rgb(122, 122, 122);
	padding-bottom: 15px;
	text-transform: capitalize;
}

.form-home-client__content .form-home-client__content-child h4:visited,
.form-home-client__content .form-home-client__content-child h4:focus,
.form-home-client__content .form-home-client__content-child h4:active{
	color:rgb(0, 158, 227)
}

.form-home-client__content .form-home-client__content-child {
	padding-bottom: 20px;
}

.form-home-client {
	padding: 20px 0;
}


ul.form-home-client__tabs,
ul.form-home-client__tabs li.form-home-client__tabs-child a {
	border: none;

}
ul.form-home-client__tabs li.form-home-client__tabs-child a:hover {
	color: #009ee3;
}

li.form-home-client__tabs-child a {
	color      : rgb(122, 122, 122);
	font-size  : 20px;
	font-weight: 400;
	line-height: 21px;
	text-transform: capitalize;
}

li.form-home-client__tabs-child a.active:active,
li.form-home-client__tabs-child a.active:visited,
li.form-home-client__tabs-child .active.nav-link
 {
	color: rgb(0, 158, 227);
}


input.ant-input {
	border-color: #7A7A7A;
}

.form-home-client__tabs-child > .nav-link {
	padding: 0.5rem 1rem 0.5rem 0;
}
ul.form-home-client__tabs{
	width: 80%;
}

.form-home-client__content .form-home-client__content-child h4.active-collapse{
	color: rgb(0, 158, 227);
}


@media only screen and (max-width: 992px) {
	.form-home-client .form-home-client__tabs {
		display: none;
	}

	.form-home-client__col .tab-content>.tab-pane {
		display: block;
	}

}

@media only screen and (min-width: 992px) {
	.form-home-client__content-child .collapse:not(.show) {
		display: block;
	}

	.form-home-client .row .form-home-client__col:nth-child(2){
		margin-top: 30px;
	}

	.form-home-client__content .form-home-client__content-child h4 {
		display: none;
	}

	.form-home-client .form-home-client__col ul.form-home-client__tabs li {
		padding-bottom: 25px;
	}
}