body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.loading{
  position: relative;
}

.loading::after {
  content: "";
  background: url('./assets/images/Cube-1s-200px.gif') no-repeat;
width: 100%;
height: 100vh;
top: 10px;
right: 5px;
background-position: center center;
position: absolute;
}

